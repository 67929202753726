import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import SEO from "./seo"
import "./layout.scss"
import Header from "../wal-common/src/components/Header/Header"
import HeaderOben from "./HeaderOben/HeaderOben"
import SocialLinks from "./SocialLinks/SocialLinks"
import Fade from "../wal-common/src/components/Transitions/Fade/Fade"
import BackToTop from "../wal-common/src/components/BackToTop/BackToTop"
import { News } from "./News/News"
import HeaderInBarOnSticky from "./HeaderInBarOnSticky/HeaderInBarOnSticky"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <SEO
          title={data.site.siteMetadata.title}
          keywords={[`Sergey`, `accordionist`, `musician`]}
        />
        <Header InBarOnSticky={HeaderInBarOnSticky} HeaderOben={HeaderOben}>
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link" href="#">Biography</a>

            <div className="navbar-dropdown">
              <Link className="navbar-item" to="/biography_en">
                Biography englisch
              </Link>
              <Link className="navbar-item" to="/biography_de">
                Biografie deutsch
              </Link>
              <Link className="navbar-item" to="/biography_ru">
                Биография русский
              </Link>
              <Link className="navbar-item" to="/biography_fr">
                Biographie français
              </Link>
            </div>
          </div>

          <Link className="navbar-item" to="/cds">
            CDs
          </Link>
          <Link className="navbar-item" to="/repertoire">
            Repertoire
          </Link>
          <Link className="navbar-item" to="/agenda">
            Agenda
          </Link>
          <Link className="navbar-item" to="/media">
            Media
          </Link>
          <Link className="navbar-item" to="/teaching">
            Teaching
          </Link>
          <Link className="navbar-item" to="/press">
            Press
          </Link>
          <Link className="navbar-item" to="/photos">
            Photos
          </Link>
        </Header>

        <main className="container section is-clearfix">
          <div className="columns">
            <div className="column is-8">
              <Fade>{children}</Fade>
            </div>
            <div className="column is-4">
              <News />
            </div>
          </div>
        </main>
        <footer className="footer has-text-centered">
          <SocialLinks />
          &copy; Sergej Tchirkov {new Date().getFullYear()}
        </footer>

        <BackToTop />
      </>
    )}
  />
)

export default Layout
