import React from 'react'
import { FaEnvelope, FaFacebook } from 'react-icons/fa'
import IconButton from '../../wal-common/src/components/Icon/IconButton/IconButton'

function SocialLinks() {
  return (
    <div className="m-b-md m-t-md has-text-centered">
      <span>
        <IconButton
          modifier="rotatedOnHover"
          url="https://www.facebook.com/sergej.tchirkov"
          Icon={FaFacebook}
        />
      </span>
      <span className="m-l-md">
        <IconButton
          modifier="rotatedOnHover"
          url="mailto:info@tchirkov.eu"
          Icon={FaEnvelope}
        />
      </span>
    </div>
  )
}

export default SocialLinks;