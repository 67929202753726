import React from "react"
import './HeaderInBarOnSticky.scss'
import BEMHelper from 'react-bem-helper'
import { Link } from 'gatsby'

const classes = new BEMHelper({
  name: 'HeaderInBarOnSticky',
});

const HeaderInBarOnSticky = ({modifier}) => {
  return (
    <Link to="/" {...classes(null, modifier, 'is-family-secondary is-size-4 p-l-md has-text-white')}>
      Sergej Tchirkov
    </Link>
  )
}

export default HeaderInBarOnSticky
