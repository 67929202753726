import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { NewsList } from "./NewsList/NewsList"

export function News() {
  return (
    <StaticQuery
      query={graphql`
        query NewsQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { fields: { slug: { glob: "/news/*/" } } }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  past
                }
              }
            }
          }
        }
      `}
      render={({ allMarkdownRemark: { edges } }) => {
        const newsCurrent = []
        const newsPast = []

        edges.forEach(
          ({
            node: {
              id,
              fields: { slug },
              frontmatter: { title, past },
            },
          }) => {
            const news = {
              slug,
              title,
              id,
            }

            if (past) {
              newsPast.push(news)
            } else {
              newsCurrent.push(news)
            }
          }
        )

        return (
          <aside className="menu">
            {newsCurrent.length > 0 && (
              <NewsList news={newsCurrent} listTitle="NEWS" />
            )}
            {newsPast.length > 0 && (
              <NewsList news={newsPast} listTitle="PAST NEWS" closed={true} />
            )}
          </aside>
        )
      }}
    />
  )
}
