import React, { Component } from 'react'
import BEMHelper from 'react-bem-helper'
import sticky from '../HoC/sticky/sticky'

import './header.scss'

const classes = new BEMHelper({
  name: 'Header',
})

class Header extends Component {
  state = {
    menuActive: false,
  }

  menuRef = React.createRef();

  componentDidMount() {
    document.body.addEventListener('click', (event) => {
      if (this.state.menuActive && this.menuRef.current && !this.menuRef.current.contains(event.target)) {
        this.setState({
          menuActive: false
        })
        event.stopPropagation()
      }
    })
  }

  render() {
    const { modifier, stickyElRef, HeaderOben, InBarOnSticky, children } = this.props
    const { menuActive } = this.state

    return (
      <div {...classes(null, modifier)}>
        <div {...classes('Oben')}>
          <div className="container">
            {<HeaderOben />}
          </div>
        </div>
        <nav
          ref={stickyElRef}
          {...classes('Navbar', null, 'navbar is-primary')}
          role="navigation"
          aria-label="main navigation"
        >
          <div className="container">
            <div className="navbar-brand">
              <InBarOnSticky modifier={modifier}/>
              <a
                role="button"
                {...classes('Burger', null, {
                  'is-active': menuActive,
                  'navbar-burger burger': true,
                })}
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
                onClick={(e) => {
                  this.setState({
                    menuActive: !this.state.menuActive,
                  })
                  e.stopPropagation()
                }}
              >
                <span aria-hidden="true"/>
                <span aria-hidden="true"/>
                <span aria-hidden="true"/>
              </a>
            </div>

            <div
              ref={this.menuRef}
              id="navbarBasicExample"
              {...classes('Menu', null, {
                'is-active': menuActive,
                'navbar-menu': true,
              })}
            >
              <div className="navbar-start">
                {children}
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

export default sticky(Header)
