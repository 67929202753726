import React, { Component } from "react"
import { FaChevronDown } from "react-icons/fa"
import { Link } from "gatsby"
import Animista from "../../../wal-common/src/components/Animista/Animista"

export class NewsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      closed: !!props.closed,
    }
  }

  render() {
    const { news, listTitle } = this.props
    const { closed } = this.state

    const listing = (
      <ul className="menu-list">
        {news.map(({ id, slug, title }) => (
          <li key={id}>
            <Link to={slug}>{title}</Link>
          </li>
        ))}
      </ul>
    )

    return (
      <>
        <p className="menu-label has-text-primary">
          {listTitle}
          {closed && (
            <span className="p-l-sm">
              <a
                className="is-link"
                href="#"
                onClick={() => {
                  this.setState({
                    closed: false,
                  })
                }}
              >
                <FaChevronDown />
              </a>
            </span>
          )}
        </p>
        {!this.props.closed && listing}
        {this.props.closed && (
          <Animista animistaClass="slide-in-top" on={!closed}>
            {listing}
          </Animista>
        )}
      </>
    )
  }
}
