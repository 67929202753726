import React, { Component } from "react"
import classnames from 'classnames'
import './Animista.scss'

class Animista extends Component {
  render() {
    const {animistaClass, on, children} = this.props;

    return (
      <div className={classnames('Animista', {
        [animistaClass]: on
      })}>
        {children}
      </div>
    )
  }
}

export default Animista
