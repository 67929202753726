import React from "react"
import BEMHelper from 'react-bem-helper'

import './HeaderOben.scss'
import { Link } from "gatsby"

const classes = new BEMHelper({
  name: 'HeaderOben',
})

const HeaderOben = () => {
  return (
    <div className="HeaderOben container p-lg">
      <Link to="/">
        <h1 {...classes('Title', null, 'title is-1 is-family-secondary')}>Sergej Tchirkov</h1>
        <h1 className="subtitle is-2">M u s i c i a n</h1>
      </Link>
    </div>
  )
}

export default HeaderOben